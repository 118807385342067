import React from "react";
import Box from "@mui/material/Box";

const Home = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <h1>Welcome to HJZInvesting</h1>
        <p>
          This is the home page. You can sign up or log in to view more
          features.
        </p>
      </div>
    </Box>
  );
};

export default Home;
