import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import samplePosts from "./BlogPosts";

const BlogPage = () => {
  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Blog
      </Typography>
      <Grid container spacing={4}>
        {samplePosts.map((post) => (
          <Grid item key={post.id} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={post.content[1]?.src || ""}
                alt={post.title}
              />
              <CardContent>
                <Typography variant="h6">{post.title}</Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {post.content[0].content.substring(0, 50)}...
                </Typography>
                <Button
                  component={Link}
                  to={`/blog/${post.id}`}
                  size="small"
                  color="primary"
                >
                  Read More
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BlogPage;
