import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Positions from "../components/Positions";

const Trades = () => {
  const [openTrades, setOpenTrades] = useState([]);
  const [closedTrades, setClosedTrades] = useState([]);

  useEffect(() => {
    fetchTrades();
  }, []);

  const fetchTrades = async () => {
    try {
      const response = await axios.get("https://www.haydenjin.com/api/trades");
      const uniqueTickers = [
        ...new Set(response.data.map((trade) => trade.ticker_symbol)),
      ];

      // Fetch the previous close prices for all unique tickers
      const priceResponse = await axios.get(
        `https://ql.stocktwits.com/batch?symbols=${uniqueTickers.join(",")}`
      );
      const priceData = priceResponse.data;

      const openTradesFormatted = [];
      const closedTradesFormatted = [];

      response.data.forEach((trade) => {
        const {
          date,
          ticker_symbol,
          average,
          percentage_of_portfolio,
          open_closed,
          closed_date,
          closed_price,
          percentage_at_close,
        } = trade;

        const previousClose = priceData[ticker_symbol]?.Last || 0;

        // Format the trade data for display
        const formattedTrade = {
          date: moment(date).format("YYYY-MM-DD"),
          ticker_symbol: `${ticker_symbol}`,
          average: `$${average.toFixed(2)}`,
        };

        if (open_closed === "open") {
          // Open trade calculations
          formattedTrade.percentage_of_portfolio = `${percentage_of_portfolio.toFixed(
            2
          )}%`;
          formattedTrade.percentageGainLoss = `${(
            ((previousClose - average) / average) *
            100
          ).toFixed(2)}%`;
          formattedTrade.timeHeld = moment().diff(moment(date), "days");
          openTradesFormatted.push(formattedTrade);
        } else if (open_closed === "closed") {
          // Closed trade calculations
          formattedTrade.percentage_of_portfolio = `${percentage_at_close.toFixed(
            2
          )}%`;
          formattedTrade.percentageGainLoss = `${(
            ((closed_price - average) / average) *
            100
          ).toFixed(2)}%`;
          formattedTrade.timeHeld = moment(closed_date).diff(
            moment(date),
            "days"
          );
          closedTradesFormatted.push(formattedTrade);
        }
      });

      setOpenTrades(openTradesFormatted);
      setClosedTrades(closedTradesFormatted);
    } catch (error) {
      console.error("Error fetching trades:", error);
    }
  };

  const StyledTableCellOpen = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "lightblue",
      color: theme.palette.common.black,
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCellClosed = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "teal",
      color: theme.palette.common.white,
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const TableWrapper = styled(TableContainer)(({ theme }) => ({
    maxWidth: "50%",
    margin: "auto",
    "@media (max-width: 600px)": {
      maxWidth: "100%",
    },
  }));

  return (
    <div>
      <Positions />
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <h1>Open Trades</h1>
      </Box>
      <TableWrapper component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCellOpen>Date</StyledTableCellOpen>
              <StyledTableCellOpen>Ticker</StyledTableCellOpen>
              <StyledTableCellOpen>Average</StyledTableCellOpen>
              <StyledTableCellOpen>% of Port</StyledTableCellOpen>
              <StyledTableCellOpen>% Gain/Loss</StyledTableCellOpen>
              <StyledTableCellOpen>Time Held (days)</StyledTableCellOpen>
            </TableRow>
          </TableHead>
          <TableBody>
            {openTrades.map((trade, index) => (
              <StyledTableRow key={index}>
                <TableCell>{trade.date}</TableCell>
                <TableCell>{trade.ticker_symbol}</TableCell>
                <TableCell>{trade.average}</TableCell>
                <TableCell>{trade.percentage_of_portfolio}</TableCell>
                <TableCell>{trade.percentageGainLoss}</TableCell>
                <TableCell>{trade.timeHeld}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <h1>Closed Trades</h1>
      </Box>
      <TableWrapper component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCellClosed>Date</StyledTableCellClosed>
              <StyledTableCellClosed>Ticker</StyledTableCellClosed>
              <StyledTableCellClosed>Average</StyledTableCellClosed>
              <StyledTableCellClosed>% of Port</StyledTableCellClosed>
              <StyledTableCellClosed>% Gain/Loss</StyledTableCellClosed>
              <StyledTableCellClosed>Time Held (days)</StyledTableCellClosed>
            </TableRow>
          </TableHead>
          <TableBody>
            {closedTrades.map((trade, index) => (
              <StyledTableRow key={index}>
                <TableCell>{trade.date}</TableCell>
                <TableCell>{trade.ticker_symbol}</TableCell>
                <TableCell>{trade.average}</TableCell>
                <TableCell>{trade.percentage_of_portfolio}</TableCell>
                <TableCell>{trade.percentageGainLoss}</TableCell>
                <TableCell>{trade.timeHeld}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
};

export default Trades;
