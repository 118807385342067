import React from "react";
import { useParams } from "react-router-dom";
import { Container, Typography, Box } from "@mui/material";
import samplePosts from "./BlogPosts";

const BlogPost = () => {
  const { id } = useParams();
  const post = samplePosts.find((post) => post.id === parseInt(id));

  if (!post) return <Typography variant="h6">Post not found</Typography>;

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        {post.title}
      </Typography>
      {post.content.map((section, index) =>
        section.type === "text" ? (
          <Typography key={index} variant="body1" paragraph>
            {section.content}
          </Typography>
        ) : section.type === "image" ? (
          <Box
            key={index}
            component="img"
            src={section.src}
            sx={{
              width:
                section.size === "full"
                  ? "100%"
                  : section.size === "large"
                  ? "75%"
                  : "50%",
              display: "block",
              margin: "auto",
            }}
            alt="Blog post visual"
          />
        ) : null
      )}
    </Container>
  );
};

export default BlogPost;
