import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  TextField,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { alpha, styled } from "@mui/material/styles";
import { auth } from "../firebase";
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";

function EnhancedTable() {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tickers, setTickers] = useState([]);
  const [newTicker, setNewTicker] = useState("");
  const [newCik, setNewCik] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const userId = auth.currentUser.uid;
  const email = auth.currentUser.email;

  // Popover handlers
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // Fetch tickers for the logged-in user
  const fetchTickers = () => {
    axios
      .get(`https://www.haydenjin.com/api/watchlist/user/${userId}`)
      .then((response) => {
        setTickers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching tickers:", error);
      });
  };

  useEffect(() => {
    fetchTickers();
  }, []);

  // Add ticker to sec table for the user and check if it exists in the tickers table
  const addTicker = () => {
    axios
      .post("https://www.haydenjin.com/api/watchlist", {
        userId, // Pass the user ID
        ticker_symbol: newTicker,
        email: email,
      })
      .then(() => {
        // Check if the ticker exists in the tickers table
        axios
          .post("https://www.haydenjin.com/api/tickers", {
            ticker_symbol: newTicker,
            cik: newCik,
          })
          .then(() => {
            setNewTicker("");
            setNewCik("");
            fetchTickers(); // Refresh the list after adding
          })
          .catch((error) => {
            // Check if the error is due to duplicate entry
            if (error.response && error.response.status === 409) {
              console.log("Ticker already exists in the tickers table.");
              // Even if it exists, refresh the tickers for the user
              setNewTicker("");
              setNewCik("");
              fetchTickers(); // Refresh the list after handling duplicate
            } else {
              console.error("Error adding ticker to tickers table:", error);
            }
          });
      })
      .catch((error) => {
        console.error("Error adding ticker to user watchlist:", error);
      });
  };

  // Delete tickers from the sec table for the user and check if it's still needed in tickers
  const deleteTickers = () => {
    selected.forEach((ticker_symbol) => {
      axios
        .delete(
          `https://www.haydenjin.com/api/watchlist/${userId}/${ticker_symbol}`
        ) // Remove from user watchlist
        .then(() => {
          // Check if other users are still watching this ticker
          axios
            .get(
              `https://www.haydenjin.com/api/watchlist/ticker/${ticker_symbol}`
            )
            .then((response) => {
              if (response.data.length === 0) {
                // No other users are watching, delete from tickers table
                axios
                  .delete(
                    `https://www.haydenjin.com/api/tickers/${ticker_symbol}`
                  )
                  .then(() => {
                    fetchTickers(); // Refresh the list after deletion
                    setSelected([]); // Reset selected state
                  })
                  .catch((error) => {
                    console.error(
                      "Error deleting ticker from tickers table:",
                      error
                    );
                  });
              } else {
                fetchTickers(); // Refresh the list if other users still watching
                setSelected([]); // Reset selected state
              }
            })
            .catch((error) => {
              console.error("Error checking other users' watchlist:", error);
            });
        })
        .catch((error) => {
          console.error("Error deleting ticker from user watchlist:", error);
        });
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tickers.map((n) => n.ticker_symbol);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, ticker_symbol) => {
    const selectedIndex = selected.indexOf(ticker_symbol);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, ticker_symbol);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (ticker_symbol) => selected.indexOf(ticker_symbol) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tickers.length) : 0;

  const visibleRows = tickers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const TableWrapper = styled(TableContainer)(({ theme }) => ({
    maxWidth: "80%",
    margin: "auto", // Center the table
    "@media (max-width: 600px)": {
      maxWidth: "100%", // Full width on small screens
    },
  }));

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>Ticker Manager</h1>
      </Box>
      <TableWrapper component={Paper}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selected.length > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
          }}
        >
          {selected.length > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected.length} selected
            </Typography>
          ) : (
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Tickers Watched
            </Typography>
          )}
          {selected.length > 0 ? (
            <Tooltip title="Delete">
              <IconButton onClick={deleteTickers}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 && selected.length < tickers.length
                    }
                    checked={
                      tickers.length > 0 && selected.length === tickers.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "select all tickers" }}
                  />
                </TableCell>
                <TableCell>Ticker Symbol</TableCell>
                <TableCell>Ticker CIK</TableCell>
                <TableCell>Latest Filing</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.ticker_symbol);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.ticker_symbol)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.ticker_symbol}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.ticker_symbol}
                    </TableCell>
                    <TableCell>{row.cik}</TableCell>
                    <TableCell>{row.latest_filing}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box padding={1}>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </Box>
      </TableWrapper>
      <Box
        padding={2}
        sx={{
          display: "flex",
          alignItems: "center",
          maxWidth: "80%",
          margin: "auto", // Center the table
          "@media (max-width: 600px)": {
            maxWidth: "100%", // Full width on small screens
          },
        }}
      >
        <Box sx={{ mr: 1 }}>
          <TextField
            id="ticker-input"
            label="Ticker"
            variant="outlined"
            size="small"
            value={newTicker}
            onChange={(e) => setNewTicker(e.target.value.toUpperCase())}
          />
        </Box>
        <Box sx={{ mr: 1 }}>
          <TextField
            id="cik-input"
            label="CIK"
            variant="outlined"
            size="small"
            value={newCik}
            onChange={(e) => setNewCik(e.target.value)}
          />
        </Box>
        <IconButton
          onClick={handlePopoverOpen}
          aria-label="How to find the CIK"
        >
          <InfoIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography>
              You can find the CIK by searching for the company on the {" "}
              <a
                href="https://www.sec.gov/search-filings/cik-lookup"
                target="_blank"
                rel="noopener noreferrer"
              >
                 SEC's website
              </a>
              . Enter all leading zeros. Example: AAPL's CIK is 0000320193
            </Typography>
          </Box>
        </Popover>
        <Button variant="contained" size="medium" onClick={addTicker}>
          Add Ticker
        </Button>
      </Box>
    </div>
  );
}

export default EnhancedTable;
