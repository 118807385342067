import "./App.css";
import Trades from "./components/Trades";
import PrivateRoute from "./auth/PrivateRoute";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import SignIn from "./auth/sign-in/SignIn";
import SignUp from "./auth/sign-up/SignUp";
import Profile from "./components/Profile";
import SEC from "./components/SEC_Notifier";
import Blog from "./components/BlogPage";
import Post from "./components/BlogPost";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/trades" element={<Trades />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<Post />} />
        <Route
          path="/sec"
          element={
            <PrivateRoute>
              <SEC />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
