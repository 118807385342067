import Image1 from "../Media/FoslNov12.png";

const samplePosts = [
  {
    id: 1,
    title: "FOSL",
    content: [
      { type: "text", content: "Fossil Group" },
      {
        type: "image",
        src: Image1,
        size: "full",
      },
      { type: "text", content: "My own projections for FOSL. Light blue is past quarters, Orange is future estimates. (Last updated Nov 12th 2024)" },
    ],
  },
  {
    id: 2,
    title: "Place holder",
    content: [
      { type: "text", content: "Introduction to placeholder content..." },
      { type: "image", src: "https://via.placeholder.com/500", size: "full" },
    ],
  },
];

export default samplePosts;
