import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import Box from "@mui/material/Box";

const Positions = () => {
  const [openPositions, setOpenPositions] = useState([]);
  const [closedPositions, setClosedPositions] = useState([]);

  useEffect(() => {
    fetchPositions();
  }, []);

  const fetchPositions = async () => {
    try {
      const response = await axios.get("https://www.haydenjin.com/api/positions");
      
      // Assuming response.data is an object with openPositions and closedPositions arrays
      const openPositionsData = response.data.openPositions;
      const closedPositionsData = response.data.closedPositions;
  
      // Extract unique tickers for both open and closed positions
      const uniqueTickers = [
        ...new Set([
          ...openPositionsData.map((position) => position.ticker_symbol),
          ...closedPositionsData.map((position) => position.ticker_symbol),
        ]),
      ];
  
      console.log(uniqueTickers);
  
      // Fetch the previous close prices for all unique tickers
      const priceResponse = await axios.get(
        `https://ql.stocktwits.com/batch?symbols=${uniqueTickers.join(",")}`
      );
      const priceData = priceResponse.data;
  
      console.log(priceData);
  
      // Process open positions
      const formattedOpenPositions = openPositionsData.map((position) => {
        const {
          ticker_symbol,
          weighted_average_price,
          total_portfolio_percentage,
        } = position;
  
        const previousClose = priceData[ticker_symbol]?.Last || 0;
        const percentageGainLoss =
          ((previousClose - weighted_average_price) / weighted_average_price) * 100;
  
        return {
          ticker_symbol,
          weighted_average_price: `$${weighted_average_price.toFixed(2)}`,
          total_portfolio_percentage: `${total_portfolio_percentage.toFixed(2)}%`,
          percentageGainLoss: `${percentageGainLoss.toFixed(2)}%`,
        };
      });
  
      // Process closed positions
      const formattedClosedPositions = closedPositionsData.map((position) => {
        const {
          ticker_symbol,
          weighted_average_price,
          closed_price,
          total_portfolio_percentage,
        } = position;
  
        // Calculate percentage gain/loss for closed positions
        const percentageGainLoss =
          ((closed_price - weighted_average_price) / weighted_average_price) * 100;
  
        return {
          ticker_symbol,
          weighted_average_price: `$${weighted_average_price.toFixed(2)}`,
          closed_price: `$${closed_price.toFixed(2)}`,
          total_portfolio_percentage: `${total_portfolio_percentage.toFixed(2)}%`,
          percentageGainLoss: `${percentageGainLoss.toFixed(2)}%`,
        };
      });
  
      setOpenPositions(formattedOpenPositions);
      setClosedPositions(formattedClosedPositions);
    } catch (error) {
      console.error("Error fetching positions:", error);
    }
  };

  const StyledTableCellOpen = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "lightblue",
      color: theme.palette.common.black,
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCellClosed = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "teal",
      color: theme.palette.common.white,
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const TableWrapper = styled(TableContainer)(({ theme }) => ({
    maxWidth: "50%",
    margin: "auto",
    "@media (max-width: 600px)": {
      maxWidth: "100%",
    },
  }));

  return (
    <div>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <h1>Open Positions</h1>
      </Box>
      <TableWrapper component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCellOpen>Ticker</StyledTableCellOpen>
              <StyledTableCellOpen>Average</StyledTableCellOpen>
              <StyledTableCellOpen>% of Port</StyledTableCellOpen>
              <StyledTableCellOpen>% Gain/Loss</StyledTableCellOpen>
            </TableRow>
          </TableHead>
          <TableBody>
            {openPositions.map((position, index) => (
              <StyledTableRow key={index}>
                <TableCell>{position.ticker_symbol}</TableCell>
                <TableCell>{position.weighted_average_price}</TableCell>
                <TableCell>{position.total_portfolio_percentage}</TableCell>
                <TableCell>{position.percentageGainLoss}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <h1>Closed Positions</h1>
      </Box>
      <TableWrapper component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCellClosed>Ticker</StyledTableCellClosed>
              <StyledTableCellClosed>Average</StyledTableCellClosed>
              <StyledTableCellClosed>Closed Price</StyledTableCellClosed>
              <StyledTableCellClosed>% of Port</StyledTableCellClosed>
              <StyledTableCellClosed>% Gain/Loss</StyledTableCellClosed>
            </TableRow>
          </TableHead>
          <TableBody>
            {closedPositions.map((position, index) => (
              <StyledTableRow key={index}>
                <TableCell>{position.ticker_symbol}</TableCell>
                <TableCell>{position.weighted_average_price}</TableCell>
                <TableCell>{position.closed_price}</TableCell>
                <TableCell>{position.total_portfolio_percentage}</TableCell>
                <TableCell>{position.percentageGainLoss}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
};

export default Positions;
