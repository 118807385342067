// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBZCss-MjhnYsicHu9Bk-U6mnOtc5rq8mQ",
  authDomain: "hjzinvesting.firebaseapp.com",
  projectId: "hjzinvesting",
  storageBucket: "hjzinvesting.appspot.com",
  messagingSenderId: "687377225456",
  appId: "1:687377225456:web:01f14ca3554f1c94fc5435",
  measurementId: "G-JQH0SZMCV3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);