// src/components/Navbar.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Assuming you use React Router for navigation
import { auth } from "../firebase"; // Import your Firebase auth instance
import { onAuthStateChanged } from "firebase/auth";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Navbar = () => {
  const [user, setUser] = useState(null);

  // State for menu anchor
  const [anchorEl, setAnchorEl] = useState(null);

  // Check the user's authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Update user state with the logged-in user or null if not logged in
    });

    // Cleanup the subscription on unmount
    return () => unsubscribe();
  }, []);

  // Handle menu open
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Use theme to detect screen size
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Navigation items
  const navItems = [
    { label: 'Home', path: '/' },
    { label: 'Positions', path: '/trades' },
    { label: 'Blog', path: '/blog' },
    { label: 'SEC Notifier', path: '/sec' },
    user ? { label: 'Profile', path: '/profile' } : { label: 'Sign In', path: '/signin' },
  ];

  return (
    <AppBar position="static">
      <Toolbar>
        {/* Logo or Title */}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          HJZInvesting
        </Typography>

        {isMobile ? (
          // Mobile view: Show hamburger menu
          <>
            <IconButton
              color="inherit"
              edge="end"
              onClick={handleMenuOpen}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {navItems.map((item) => (
                <MenuItem
                  key={item.label}
                  component={Link}
                  to={item.path}
                  onClick={handleMenuClose}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          // Desktop view: Show buttons
          <>
            {navItems.map((item) => (
              <Button
                key={item.label}
                color="inherit"
                component={Link}
                to={item.path}
              >
                {item.label}
              </Button>
            ))}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
