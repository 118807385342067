import React, { useState, useEffect } from "react";
import { auth } from "../firebase"; // Import your Firebase auth instance
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const Profile = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Fetch user details from Firebase Auth
  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUser(currentUser);
    } else {
      // If no user is found, redirect to the Sign In page
      navigate("/signin");
    }
  }, [navigate]);

  // Handle sign-out
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/signin"); // Redirect to Sign In page after signing out
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card sx={{ maxWidth: 400, width: "100%" }}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Profile
          </Typography>

          {/* Display the user's email */}
          {user && (
            <>
              <Typography variant="body1">Email: {user.email}</Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={handleSignOut}
              >
                Sign Out
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Profile;
